@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.ski-theme {
    background: linear-gradient(to bottom, #f5f5f5, #d6d6d6);
    animation: snowfall 60s linear infinite;
}

@keyframes snowfall {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}

.business-hours__title {
    color: #333;
    font-size: 24px;
    text-align: center;
    font-family: 'Permanent Marker', cursive;
}

.business-hours__place {
    margin-bottom: 20px;
}

.business-hours__place-name {
    color: #333;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: 'Permanent Marker', cursive;
}

.business-hours__hours-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.business-hours__hours-item {
    color: #333;
    font-size: 14px;
    margin-bottom: 10px;
}

.business-hours__hours-item::before {
    content: '\2744';
    font-size: 18px;
    margin-right: 10px;
}
.usiness-hours__gridb {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.business-hours__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.business-hours__place {
    width: 45%;
}

